.App
{
}
.navbar
{
  background-color: #E5E9ED;
  margin: 0.5rem 1rem;
  text-transform: uppercase;
}

.navbar a
{
  color: #000;
  font-size: 0.9rem;
}
.navbar .navbar-brand
{
  margin-right: 3rem;
}

.jumbotron {
  padding: 2rem;
  margin: 0.5rem 1rem 2rem 1rem;
  background-color: transparent;
  background-image: url('img/eclipse-hero.jpg');
  background-position: center 25%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 2rem;
}
.jumbotron .container {
  text-align: right;
  color: #fff;
}

.jumbotron h1
{
  font-size: 2.9rem;
  font-weight: 700;
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.mainContainer
{
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}

article h2
{
  font-family: 'Roboto', Arial;
  font-size: 1.8rem;
}
article p
{
  font-family: 'Open Sans', sans-serif;
}
.sidebar .row
{
  margin: 0 0 2rem 0;
}
.sidebar i
{
  font-size: 3rem;
  background-color: #6083C2;
  border-radius: 9rem;
  color: #fff;
  margin: 1rem 0 0 0;
  padding: 1.2rem 1.2rem;
}
.sidebar .row:nth-child(2) i
{
  padding: 1.2rem 1.5rem;
}
.sidebar .row:nth-child(3) i
{
  padding: 1.5rem 1.2rem;
  font-size: 2.4rem;
}
.sidebar h2
{
  color: #6083C2;
  font-family: 'Roboto', Arial;
  font-size: 1.1rem;
  font-weight: 700;
}
.sidebar p
{
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
}
footer
{
  background-color: #E5E9ED;
  font-size: 0.9rem;
  min-height: 10rem;
  padding: 2rem 2rem;
}

footer .navbar-brand
{
  text-align: center;
}

footer .copyright{
  font-size: 0.6rem;
}

footer ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}

footer address
{
  margin-top: 1rem;
}
